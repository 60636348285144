import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import ProgressBar from "../../ProgressBars/ProgressBar";
import ReversedProgressBar from "../../ProgressBars/ReversedProgressBar";
import { alpha } from "@mui/material/styles";
import { theme } from "../../../Theme/index";
import { getUrl } from "src/Utils/Common";
import { useMediaQuery } from "@mui/material";

function SourceShareTable() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [tableData, setTableData] = useState([]); // Holds sanitized API response data
  const [skus, setSkus] = useState([]);
  const [selectedSku, setSelectedSku] = useState("Afia Corn 1.5L");
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Sanitize invalid JSON values like NaN
  const sanitizeJSON = (text) => {
    return text.replace(/NaN/g, "null");
  };

  const fetchData = async (sku) => {
    if (!sku) {
      setTableData([]); // Clear table if SKU is deselected
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${getUrl()}/sim/brand_interaction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-cors-api-key": "temp_8c8f91f122df0ef9daa49a6701f13f76",
        },
        body: JSON.stringify({
          project_name: "newsavola2",
          sku: sku,
          n: 5,
          low: -0.1,
          high: 0.1,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const text = await response.text();
      const sanitizedText = sanitizeJSON(text); // Replace NaN with null
      const result = JSON.parse(sanitizedText); // Parse sanitized JSON
      const rows = parseTableData(result); // Parse sanitized data into table rows
      setTableData(rows);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  // Function to fetch SKUs
  useEffect(() => {
    async function fetchSkus() {
      try {
        const response = await fetch(
          `${getUrl()}/sim/get_sku_by_project_name?project_name=newsavola2`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-cors-api-key": "temp_8c8f91f122df0ef9daa49a6701f13f76",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const skus = await response.json();
        setSkus(skus.skus);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }

    fetchSkus();
    fetchData(selectedSku); // ✅ Ensure correct SKU is passed on mount
  }, []);

  const handleChange = (event, newValue) => {
    setSelectedSku(newValue || "");
    if (!newValue) {
      setTableData([]); // Clear table if SKU is deselected
      return;
    }
    fetchData(newValue); // ✅ Use the existing function instead of redefining it
  };

  const parseTableData = (data) => {
    const parsedRows = [];
    const keys = Object.keys(data);
    keys.forEach((key) => {
      const brands = data[key];
      Object.keys(brands).forEach((brand) => {
        const details = brands[brand];
        parsedRows.push({
          brandValueIdx: details?.abs_interaction || 0,
          brandName: brand,
          pricingMinus: details?.Price_level || details?.price || null,
          pricingPlus: details?.zero_Price || null,
          rgmIdx: details?.fair_share || 0,
        });
      });
    });
    return parsedRows;
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      padding: "5px",
      color: theme.palette.primary.dark,
      fontSize: theme.typography.fontSize.xxsmall,
      textAlign: "center",
      justifyContent: "center",
      "@media (max-width: 600px)": {
        fontSize: "8px", // Reduce font size on mobile
      },
    },
    [`&.${tableCellClasses.body}`]: {
      textTransform: "capitalize",
      color: theme.palette.primary.dark,
      fontSize: 10,
      textAlign: "center",
      padding: "3px",
      "@media (max-width: 600px)": {
        fontSize: "8px", // Reduce font size on mobile
        padding: "2px", // Reduce padding to save space
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    height: 30, // Reduce the row height
  }));

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;

  return (
    <>
      <Autocomplete
        options={skus}
        value={selectedSku}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select SKU"
            variant="outlined"
            size="small"
            placeholder="Search or select SKU"
            sx={{
              width: "100%",
              maxWidth: 300, // Ensure dropdown doesn't shrink too much
              "@media (max-width: 600px)": {
                width: "100%", // Full width on mobile
                fontSize: "10px",
              },
            }}
          />
        )}
        sx={{
          width: 300,
          marginBottom: 2,
          ml: 3,
          "@media (max-width: 600px)": {
            width: "100%", // Make sure it stretches properly on mobile
            ml: 0, // Remove left margin on small screens
          },
        }}
      />

      <Typography fontWeight={theme.typography.fontWeightBold} p={1}>
        Brand Interaction
      </Typography>
      {selectedSku ? (
        <TableContainer
          sx={{
            height: 300,
            overflowX: "auto", // Enables horizontal scrolling on mobile
            "@media (max-width: 600px)": {
              width: "100%", // Ensure it fits within the screen width
            },
          }}
        >
          {" "}
          <Table aria-label="customized table">
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "#ffffff",
                zIndex: 10,
                // "@media (max-width: 600px)": {
                //   display: "none", // Hide table headers on mobile for a cleaner look
                // },
              }}
            >
              <TableRow>
                <StyledTableCell>Sourcing vs Fair Share</StyledTableCell>
                <StyledTableCell>Pricing (-10%)</StyledTableCell>
                <StyledTableCell>Brand</StyledTableCell>
                <StyledTableCell>Pricing (+10%)</StyledTableCell>
                <StyledTableCell>Sourcing vs Fair Share</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, idx) => (
                <StyledTableRow key={idx}>
                  <StyledTableCell>
                    {row.brandValueIdx.toFixed(2)}
                  </StyledTableCell>

                  {/* Hide Pricing (-10%) column on small screens */}
                  {/* {!isMobile && ( */}
                  <StyledTableCell>
                    {row.pricingMinus !== null ? (
                      <ReversedProgressBar
                        bgcolor={alpha("#BF83FF", 0.6)}
                        progressChild={row.pricingMinus * 10}
                        number={row.pricingMinus.toFixed(2)}
                      />
                    ) : (
                      "N/A"
                    )}
                  </StyledTableCell>
                  {/* )} */}

                  <StyledTableCell>{row.brandName}</StyledTableCell>

                  {/* Hide Pricing (+10%) column on small screens */}
                  {/* {!isMobile && ( */}
                  <StyledTableCell>
                    {row.pricingPlus !== null ? (
                      <ProgressBar
                        bgcolor={alpha("#FA5A7D", 0.6)}
                        progressChild={row.pricingPlus * 10}
                        number={row.pricingPlus.toFixed(2)}
                      />
                    ) : (
                      "N/A"
                    )}
                  </StyledTableCell>
                  {/* )} */}

                  <StyledTableCell>{row.rgmIdx.toFixed(2)}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography color="textSecondary" sx={{ ml: 3 }}>
          Please select an SKU to see data.
        </Typography>
      )}
    </>
  );
}

export default SourceShareTable;
