import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "../Drawer/Drawer";
import AppBar from "../Appbar/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Marketeers from "../../../Assets/MarketeersLogo.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Outlet } from "react-router-dom";
import { NestedList } from "../List/List";
import { theme } from "../../../Theme/index";
import SearchBar from "../Searchbar/SearchBar";
import RightBar from "../Rightbar/RightBar";
import { Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom"; // Import useLocation hook

const ContentWrapper = styled("div")(({ theme }) => ({
  paddingTop: "65px", // Adjust according to your header height
}));

const TempHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  height: "15px",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Navbar() {
  const drawerWidth = useMediaQuery(theme.breakpoints.down("sm")) ? 150 : 200;

  const DrawerHeader = styled("div")(({ theme }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: drawerWidth,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", // Ensures logo and close button align properly
    height: "65px",
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  }));

  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const [page, setPage] = React.useState("Dashboard & KPIs");

  React.useEffect(() => {
    if (!isTablet) {
      handleDrawerOpen();
    }
  }, [isTablet]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const outletStyle = {
    flexGrow: 1,
    p: isTablet ? 1 : 3,
    bgcolor: "#FAFBFC",
    minHeight: "100vh",
    marginTop: isTablet ? "50px" : "55px",
    width: "100%", // Ensures content never overflows
    maxWidth: "100vw", // Prevents extra width issues
    overflowX: "hidden", // Hides horizontal scrolling
    transition: "margin-left 0.3s ease",
    marginLeft: open && !isMobile ? `${drawerWidth}px` : "0px", // Shifts content only if screen is not mobile
  };

  return (
    <Box sx={{ display: "flex", width: "100vw", overflowX: "hidden" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} height="65px" elevation={0}>
        <Toolbar
          sx={{
            minHeight: "65px",
            justifyContent: isTablet ? "space-between" : "flex-start",
            paddingX: isTablet ? "10px" : "24px",
          }}
        >
          <Box display="flex" flexDirection="row">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h1"
              fontWeight={theme.typography.fontWeightSemiBold}
              noWrap
            >
              {page}
            </Typography>
          </Box>
          <SearchBar />
          <RightBar />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          display: open || !isMobile ? "block" : "none", // Fully hide on mobile when closed

          "& .MuiDrawer-paper": {
            width: isMobile ? "60vw" : drawerWidth,
            maxWidth: "80vw", // Prevents it from becoming too large on mobile
            borderWidth: 0,
          },
        }}
      >
        <DrawerHeader>
          {open && (
            <img
              src={Marketeers}
              alt="Marketeers Logo"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          )}
          {open && isTablet && (
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <ContentWrapper>
          <NestedList open={open} setTitle={setPage} />
        </ContentWrapper>
      </Drawer>
      <Box component="main" sx={outletStyle}>
        <Outlet />
      </Box>
    </Box>
  );
}
