import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  position: "fixed", // Ensures it overlays but doesn't block content
  left: 0,
  top: 0,
  height: "100vh",
  zIndex: 1300, // Ensures drawer is above content
  // backgroundColor: theme.palette.background.paper,

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "auto",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    display: "none", // Hide scrollbar for WebKit browsers (Chrome, Safari)
  },
  "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
  "scrollbar-width": "none", // Hide scrollbar for Firefox
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  overflowY: "auto",
  width: 0, // 👈 Ensure the width is fully collapsed
  visibility: "hidden", // 👈 Hide when closed
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  "&::-webkit-scrollbar": {
    display: "none", // Hide scrollbar for WebKit browsers (Chrome, Safari)
  },
  "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
  "scrollbar-width": "none", // Hide scrollbar for Firefox
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  maxWidth: drawerWidth, // Prevents it from taking full page width
  width: "auto", // Adjusts width dynamically
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default Drawer;
