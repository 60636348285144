import BarChartWob from "../../Components/Charts/ActualVsExpectedMixChart/BarChartWob";
import TwoColumnChart from "../../Components/Charts/TwoColumnChart/TwoColumnChart";
import SalesColumnChart from "../../Components/Charts/SalesColumnChart/SalesColumnChart";
import BrandSales from "../../Components/Charts/ActualVsExpectedMixChart/BrandSales";
import TwoColumnChartPriceIndex from "src/Components/Charts/twoColumnChartPriceIndex/twoColumnChartPriceIndex";
import MarketComparisonTable from "src/Components/Tables/MarketComparison/MarketComparison";
import BarChartWobContribution from "src/Components/Charts/BarChartWobContribution/BarChartWobContribution";
import { Box } from "@mui/material";

// Responsive adjustments
export const data = [
  {
    id: "TwoColumnChart1",
    component: (
       // <TwoColumnChartPriceIndex
        // title={"Value Sales Growth vs. YO"}
        // />
      <Box sx={{ overflowX: "auto", width: "100%"  }}>
        <MarketComparisonTable />
      </Box>
    ),
    name: "Price Index",
    xs: 12, // Full width on mobile
     sm: 12, // Full width on small screens
    md: 6, // Half width on medium screens
  },
  {
    id: "volumeSalesChart",
    component: <TwoColumnChart />,
    name: "Value Sales YA",
    xs: 12, // Full width on mobile
     sm: 12,
    md: 4,
  },
  {
    id: "BrandSales",
    component: <Box sx={{ width: "100%", overflow: "hidden" }}><BrandSales /></Box>,
    name: "Brand Sales",
    xs: 12,
     sm: 12,
    md: 12,
  },
  {
    id: "WobData",
    component: (
      <Box sx={{  overflowX: "auto", width: "100%"}}>
        <BarChartWob />
      </Box>
    ),
    name: "WobData",
    xs: 12,
     sm: 6,
    md: 6,
  },
  {
    id: "ContributionData",
    component: (
      <Box sx={{ overflowX: "auto" }}>
        <BarChartWobContribution />
      </Box>
    ),
    name: "ContributionData",
    xs: 12,
     sm: 6,
    md: 6,
  },
];
