import { Box, Typography, Paper, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../Theme/index";
import LineChart from "../../Components/Charts/LineChart/LineChart";
import GapsAndSolutionBox from "../../Components/Boxes/GapsAndSolutionBox/GapsAndSolutionBox";
import ThreePieChart from "../../Components/Charts/ThreePieChart/ThreePieChart";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DroppableAddSection from "../../DragAndDrop/DroppableAddSection";
import DroppableGrid from "../../DragAndDrop/DroppableGrid";

import TopProducts from "../../Components/Tables/TopProductsTable/TopProducts";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import UnknownMixChart from "../../Components/Charts/UnknownMixChart/UnknownMixChart";

import AddSection from "../../Components/Partials/AddSection/AddSection";
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  closestCenter,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { SortableChart } from "../StatisticsTest/components/SortableChart";

function GapsAndSolutions() {
  const handleDragStart = (event) => setActiveId(event.active.id);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) {
      setActiveId(null);
      return;
    }

    setItems((prev) => {
      const fromMainDash = prev.mainDash.find((item) => item.id === active.id);
      const fromAddSection = prev.addSection.find(
        (item) => item.id === active.id
      );

      if (fromMainDash) {
        const updatedMainDash = [...prev.mainDash];
        const draggedItemIndex = updatedMainDash.findIndex(
          (i) => i.id === active.id
        );
        const overIndex = updatedMainDash.findIndex((i) => i.id === over.id);

        const [draggedItem] = updatedMainDash.splice(draggedItemIndex, 1);
        updatedMainDash.splice(overIndex, 0, draggedItem);

        return { ...prev, mainDash: updatedMainDash };
      }

      if (fromAddSection) {
        const updatedAddSection = prev.addSection.filter(
          (i) => i.id !== active.id
        );
        const updatedMainDash = [...prev.mainDash, fromAddSection];

        return { mainDash: updatedMainDash, addSection: updatedAddSection };
      }

      return prev;
    });

    setActiveId(null);
  };

  const toggleAddSection = () => setIsAddSectionOpen((prev) => !prev);

  const handleAddComponent = (id) => {
    setItems((prev) => {
      const item = prev.addSection.find((i) => i.id === id);
      if (!item) return prev;

      const updatedAddSection = prev.addSection.filter((i) => i.id !== id);
      const updatedMainDash = [...prev.mainDash, item];

      return { mainDash: updatedMainDash, addSection: updatedAddSection };
    });
    setIsAddSectionOpen(false);
  };

  const RemoveComponent = (id) => {
    setItems((prev) => {
      const item = prev.mainDash.find((i) => i.id === id);
      const updatedMainDash = prev.mainDash.filter((i) => i.id !== id);
      const updatedAddSection = [...prev.addSection, item];

      return { mainDash: updatedMainDash, addSection: updatedAddSection };
    });
  };

  const [activeId, setActiveId] = useState();
  const [isAddSectionOpen, setIsAddSectionOpen] = useState(false);

  const TitleStyle = {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize.medium,
    paddingY: 1,
    color: theme.palette.primary.main,
  };

  const TextStyle = {
    padding: 1,
    fontSize: theme.typography.fontSize.xxsmall,
  };

  const [items, setItems] = useState({
    addSection: [
      {
        id: "LineChart",
        component: (
          <GapsAndSolutionBox Chart={<LineChart Title={"Volume Share"} />} />
        ),
        name: "Volume Share",
        xs: 12,
        md: 9,
      },
      {
        id: "PieChart",
        component: (
          <ThreePieChart Title={"Volume Share"} Sign={"%"} Color={"#7735FD"} />
        ),
        name: "Volume Share",
        xs: 12,
        md: 3,
      },
    ],
    mainDash: [
      {
        id: "TopProducts",
        component: <TopProducts />,
        name: "Top Products",
        xs: 12,
        md: 12,
      },
    ],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  const ComponentBar = {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "10px",
    padding: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  };
  const CloseButton = {
    alignSelf: "flex-end",
    padding: 0,
    margin: 0,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    cursor: "pointer",
  };
  const BoxStyle = {
    display: "flex",
    flexDirection: "column",
    height: 200,
  };

  const firstGapData = [
    {
      name: "Volume Sales Y22",
      type: "column",
      data: [186570, 158672, 30041],
    },
    {
      name: "Volume Sales Y23",
      type: "column",
      data: [181667, 157660, 15742],
    },
  ];

  const firstGapNames = ["Al Arabi", "Shams", "Zaharati"];

  const secondGapData = [
    {
      name: "Base Market Share",
      type: "column",
      data: [4.96],
    },
    {
      name: "New Market Share",
      type: "column",
      data: [5.76],
    },
  ];

  const secondGapNames = ["Afia Corn 1.5L"];

  const volumeShares = [7.2, 6.9, 3.9, 10.7, 71.4];

  const volumeNames = ["Halah", "Hayat", "Nakheel", "Noor", "Other Brands"];

  return (
    <Box>
      <AddSection sx={{ m: 2 }} toggleOpen={toggleAddSection} />

      {isAddSectionOpen && (
        <Box component={Paper} sx={ComponentBar}>
          <CloseOutlinedIcon sx={CloseButton} onClick={toggleAddSection} />
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {items.addSection.map((item) => (
              <Box
                key={item.id}
                onClick={() => handleAddComponent(item.id)}
                sx={{
                  cursor: "pointer",
                  padding: 2,
                  border: "1px solid #ccc",
                  margin: 1,
                  borderRadius: "4px",
                }}
              >
                <Typography>{item.name}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items.mainDash} strategy={rectSortingStrategy}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <DroppableGrid
                id="mainDash"
                children={items.mainDash}
                RemoveComponent={RemoveComponent}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Box component={Paper} sx={BoxStyle}>
                <Grid container>
                  <Grid
                    item
                    xs={5}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <Box>
                      <Typography sx={TitleStyle}>
                        Underperforming Products
                      </Typography>
                      <Typography sx={TextStyle}>
                        AlArabi, Shams, and Zaharati all have a Growth Index
                        below 1.00, indicating they are significantly
                        underperforming compared to the market benchmark. These
                        products are not meeting market expectations and may
                        require strategic adjustments
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={4}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <UnknownMixChart
                      data={firstGapData}
                      names={firstGapNames}
                      chartHeight={205}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "columns",
                      textAlign: "center",
                    }}
                  >
                    <Box>
                      <Typography sx={TitleStyle}>Opportunity</Typography>
                      <Typography sx={TextStyle}>
                        <TipsAndUpdatesIcon /> Revising pricing strategies to
                        enhance competitiveness.
                      </Typography>
                      <Typography sx={TextStyle}>
                        <TipsAndUpdatesIcon /> Increasing marketing efforts to
                        boost visibility and sales.
                      </Typography>
                      <Typography sx={TextStyle}>
                        <TipsAndUpdatesIcon />
                        Evaluating product features or quality to better meet
                        customer needs.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box component={Paper} sx={BoxStyle}>
                <Grid container>
                  <Grid
                    item
                    xs={5}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <Box>
                      <Typography sx={TitleStyle}>
                        Products Below Market Benchmark but Above 1.00
                      </Typography>
                      <Typography sx={TextStyle}>
                        Afia and Sunny have a Growth Index greater than 1.00 but
                        are still below the benchmark set by the Oil Category
                        (Growth Index of 1.00). While these products are
                        performing better than the underperformers, there is
                        room for improvement to reach or exceed the market
                        benchmark.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={4}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <UnknownMixChart
                      data={secondGapData}
                      names={secondGapNames}
                      minValue={0}
                      maxValue={7}
                      chartHeight={205}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "columns",
                      textAlign: "center",
                    }}
                  >
                    <Box>
                      <Typography sx={TitleStyle}>Opportunity</Typography>
                      <Typography sx={TextStyle}>
                        <TipsAndUpdatesIcon /> Enhancing promotional activities
                        to improve market share, decrease the price of Afia Corn
                        1.5L from 25.28 to 23.28.
                      </Typography>
                      <Typography sx={TextStyle}>
                        <TipsAndUpdatesIcon /> Adjusting product positioning or
                        value propositions.
                      </Typography>
                      <Typography sx={TextStyle}>
                        <TipsAndUpdatesIcon />
                        Analyzing customer feedback and adjusting the product
                        offerings accordingly.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box component={Paper} sx={BoxStyle}>
                <Grid container>
                  <Grid
                    item
                    xs={5}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <Box>
                      <Typography sx={TitleStyle}>Top Performers</Typography>
                      <Typography sx={TextStyle}>
                        Halah, Hayat, Nakheel, and Noor have a Growth Index
                        greater than 1.00 and are performing well compared to
                        the market benchmark. These products are leveraging
                        their market presence effectively.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <ThreePieChart
                      Sign={"%"}
                      Color={"#FF8F0D"}
                      Sales={volumeShares}
                      Names={volumeNames}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "columns",
                      textAlign: "center",
                    }}
                  >
                    <Box>
                      <Typography sx={TitleStyle}>Opportunity</Typography>
                      <Typography sx={TextStyle}>
                        <TipsAndUpdatesIcon /> Continue investing in successful
                        marketing strategies.
                      </Typography>
                      <Typography sx={TextStyle}>
                        <TipsAndUpdatesIcon /> Explore opportunities to expand
                        their market reach or introduce complementary products.
                      </Typography>
                      <Typography sx={TextStyle}>
                        <TipsAndUpdatesIcon />
                        Maintain or improve pricing strategies to sustain their
                        competitive edge.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </SortableContext>
      </DndContext>
    </Box>
  );
}

export default GapsAndSolutions;
