import React from "react";
import { useNavigate } from "react-router-dom";
import {
  List,
  ListItemText,
  Stack,
  ListItem,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import CrisisAlertOutlinedIcon from "@mui/icons-material/CrisisAlertOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import SettingsSuggestOutlined from "@mui/icons-material/SettingsSuggestOutlined";
import { theme } from "../../../Theme";
import CollapsibleList from "./CollapsibleList";

export function NestedList({ open, setTitle }) {
  const [active, setActive] = React.useState(
    localStorage.getItem("active") || "dashboard"
  );
  const navigate = useNavigate();

  const handleClick = (link) => {
    console.log(link.split("/")[0])
    setActive(link.split("/")[0]);
    navigate(`/${link}`);
  };

  React.useEffect(() => {
    localStorage.setItem("active", active);
  }, [active]);
  const handleLogout = () => {
    // Clear local storage
    localStorage.removeItem("userId");
    localStorage.removeItem("projectId");

    // Redirect to login page
    navigate("/login");
  };
  const MarketOverview = [
    {
      subPath: "dashboard",
      icon: <PieChartOutlineOutlinedIcon />,
      text: "Dashboard & KPIs",
      onClick: handleClick,
      activeCondition: "dashboard",
      active,
    },
    {
      subPath: "gaps-solution",
      icon: <TipsAndUpdatesOutlinedIcon />,
      text: "Gaps & Opportunities",
      onClick: handleClick,
      activeCondition: "gaps-solution",
      active,
    },
    // { subPath: 'market-dynamics', icon: <EqualizerOutlinedIcon />, text: 'Market Dynamics', onClick: handleClick,activeCondition:"market-dynamics", active },
  ];
  const StrategyCreation=[
    { subPath: 'pricing-architecture', icon: <ShoppingCartOutlinedIcon />, text: 'Pricing & Architecture', onClick: handleClick,activeCondition:"pricing-architecture", active, disabled: false },
    { subPath: 'promotion-visibility', icon: <ShoppingBagOutlinedIcon />, text: 'Promotion & Visibility', onClick: handleClick,activeCondition:"promotion", active, disabled: true},
    { subPath: 'assortment-dist', icon: <ShowChartOutlinedIcon />, text: 'Assortment & Dist', onClick: handleClick,activeCondition:"assortment", active, disabled: true },
    { subPath: 'trade-management', icon: <SmsOutlinedIcon />, text: 'Trade Management', onClick: handleClick,activeCondition:"trade", active, disabled: true },
    { subPath: 'brand-value', icon: <SettingsSuggestOutlined />, text: 'Brand Value Creation', onClick: handleClick,activeCondition:"brand", active, disabled: true },
    
  ]
  const DecisionCenter = [
    {
      subPath: "smart-simulator",
      icon: <SmartToyOutlinedIcon />,
      text: "Smart Simulator",
      onClick: handleClick,
      activeCondition: "smart-simulator",
      active,
    },
    {
      subPath: "scenario-builder",
      icon: <BuildOutlinedIcon />,
      text: "Scenario Builder",
      onClick: handleClick,
      activeCondition: "scenario-builder",
      active,
    },
    {
      subPath: "optimization",
      icon: <AutoFixHighIcon />,
      text: "Optimization",
      onClick: handleClick,
      activeCondition: "optimization",
      active,
    },
  ];

  return (
    <Stack direction="column" justifyContent="space-between">
      <List component="nav" aria-labelledby="nested-list-subheader">
        <CollapsibleList
          title="Market Overview"
          icon={<InsertChartOutlinedIcon />}
          items={MarketOverview}
          drawerOpen={open}
          setTitle={setTitle}
        />
        <CollapsibleList
          title="Strategy Creation"
          icon={<CrisisAlertOutlinedIcon />}
          items={StrategyCreation}
          drawerOpen={open}
          setTitle={setTitle}
        />
        <CollapsibleList
          title="Decision Center"
          icon={<AccountTreeOutlinedIcon />}
          items={DecisionCenter}
          drawerOpen={open}
          setTitle={setTitle}
        />
      </List>

      <ListItem disablePadding>
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
            <LogoutOutlinedIcon sx={{ color: theme.palette.primary.main }} />
          </ListItemIcon>

          <ListItemText
            sx={{
              color: theme.palette.primary.main,
              display: open ? "auto" : "none",
            }}
          >
            Sign Out
          </ListItemText>
        </ListItemButton>
      </ListItem>
    </Stack>
  );
}
