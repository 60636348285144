/* eslint-disable react/react-in-jsx-scope */
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Paper, IconButton } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"; // Draggable icon
import "../charts.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DropDownDashboard } from "../../Dashboard/DropDownDashboard";
import PropTypes from "prop-types";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
// import { setCurrentChartType } from "src/Redux/chartSlice/chartSlice";

export const SortableChart = ({ user, RemoveComponent }) => {
  const dispatch = useDispatch();
  const currentChartType = useSelector((state) => state.chart.currentChartType);

  SortableChart.propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      component: PropTypes.node,
    }),
    RemoveComponent: PropTypes.func.isRequired,
  };

  useEffect(() => {
    console.log(`Current chart type: ${currentChartType}`);
    // Update the chart rendering based on the current type
    // This could involve updating state or re-rendering the component
  }, [currentChartType]);

  // const handleChartChange = (event) => {
  //   const chartType = event.detail;
  //   console.log(`Received chart change event: ${chartType}`);
  //   // You might want to update the chart rendering based on the chartType
  //   // This could involve updating state or re-rendering the component
  // };
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: user.id });

  const style = {
    display: "flex",
    flexDirection: "column",
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const menuConfig = {
    // New: ["Document", "From Markdown file", "From HTML file"],
    brand: ["AFIA", "AL ARABI", "LITE LIFE", "ABAZEER"],
    segment: ["CANOLA OIL", "SUNFLOWER OIL"],

    // Export: ["PDF", "Github Gist"],
  };
  return (
    <Paper
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 0, // Remove padding
      }}
      ref={setNodeRef} // Still need to set ref on Paper for drag handling
      style={style}
      className="user-card"
      elevation={3}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton onClick={RemoveComponent} sx={{ mr: 1 }}>
          <CloseOutlinedIcon sx={{ fontSize: 16 }} />
        </IconButton>
        <IconButton
          {...attributes}
          {...listeners}
          aria-label="drag"
          sx={{ cursor: "grab", fontSize: 16 }}
        >
          <DragIndicatorIcon />
        </IconButton>
      </div>

      <div
        style={{
          flex: 1, // Allow the component to take all available space
          display: "flex",
          flexDirection: "column",
        }}
      >
        {user.name === "Brand Sales" ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <DropDownDashboard menuConfig={menuConfig} />
            </div>

            {user.component}
          </>
        ) : (
          user.component
        )}
      </div>
    </Paper>
  );
};
