import {
    Box,
    FormControl,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    Typography,
  } from "@mui/material";
  
  import React, { useEffect, useState } from "react";
  import {
    bestOptimization,
    brandOptimization,
    skuOptimization,
  } from "src/Components/Network/Lib/Simulator";
  import { useTheme } from "@mui/material/styles";
  import { Button } from "@mui/material";
  import Dialog from "@mui/material/Dialog";
  import DialogActions from "@mui/material/DialogActions";
  import DialogContent from "@mui/material/DialogContent";
  
  import Table from "@mui/material/Table";
  import TableBody from "@mui/material/TableBody";
  import TableCell, { tableCellClasses } from "@mui/material/TableCell";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import { styled } from "@mui/material/styles";
  
  import TextField from "@mui/material/TextField/TextField";
  import deleteEdit from "../../Assets/delete.svg";
  import ImgNoData from "../../Assets/noData.png";
  import { toastError, toastSuccess } from "../../Utils/Toast";
  import { ToastContainer } from "react-toastify";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import { Loading } from "../../Components/Loading";
  import {Grid} from "@mui/material";
  import { Loading2 } from "../../Components/Loading";
  
  
  import {
    ChartDountMarketShare,
    ChartDountValueShare,
    ChartDountRevenue,
  } from "../../Components/Charts";
  import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
  // Import toastify css file
  import "react-toastify/dist/ReactToastify.css";
  
  import { TablePopUp } from "../../Components/Table";
  import { useRef } from "react";
  
  import AddIcon from '@mui/icons-material/Add';
  
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
  const Optimization = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      padding: "1px",
      fontSize: 12,
      [`&.${tableCellClasses.head}`]: {
        fontWeight: "bold",
        backgroundColor: theme.palette.action.hover,
        textAlign: "center",
        padding: "10px",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 12,  
        textAlign: "center",
        padding: "0px",
        // border: "5px solid gray"
        // fontFamily: "Barlow Semi Condensed",
      },
    }));
  
    const [valueBrand, setValueBrand] = useState("");
    const [objective, setObjective] = useState("");
    const [brand, setBrand] = useState([]);
    const [skus, setSKUs] = useState([]);
    const [selectedSkus, setSelectedSkus] = useState([]);
  
    const inputRef = useRef([]);
    const [flag, setFlag] = useState(true);
    const [open, setOpen] = useState(false);
    const [tableDataList, setTableDataList] = useState([]);
    const [resultBestOptimization, setResultBestOptimization] = useState({});
    const [id, setID] = useState(false);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [priceIncrease, setPriceIncrease] = useState(0);
    const [priceDecrease, setPriceDecrease] = useState(0);
    const [intervals, setIntervals] = useState(0);
  
    var i = 1;
    useEffect(() => {
      brandOptimization().then((response) => {
        setBrand(response.data.brands);
      });
    }, []);
  
    const handleBrand = (e) => {
      var arr = [];
      setTableDataList([]);
      setValueBrand(e.target.value);
  
      skuOptimization(e.target.value).then((response) => {
        console.log(response.data)
        Object.keys(response.data).forEach(key => {
          arr.push({
            id: i++,
            sku: key,
            price_decrease: Number(0),
            interval: Number(0),
          });
        });
  
        setSKUs(arr);
        setSelectedSkus(arr)
        inputRef.current = arr;
      });
    };
  
    const ref = useRef("");

    const handlePerChange = (event, type) => {
      let newValue = event.target.value;
  
      // Allow only numbers
      if (!/^\d{0,3}$/.test(newValue)) return;
  
      // Convert to number and enforce bounds
      if(type === "increase"){
      if (newValue !== "" && (Number(newValue) < 0 || Number(newValue) > 100)) {
        return;
      }
      setPriceIncrease(newValue);
      }
      else if(type === "decrease"){
        if (newValue !== "" && (Number(newValue) > 0 || Number(newValue) > -100)) {
          return;
        }
      setPriceDecrease(newValue);
      }
    };
  
    const handleChange = (e, row, key) => {
      e.preventDefault();
      let newArr = [...selectedSkus];
      if(key === "price_decrease"){
        for (const i in newArr) {
          if (newArr[i]["id"] === row) {
            if(parseInt(e.target.value) > 0) {
              newArr[i]["price_decrease"] = Number(e.target.value)*-1;
            }
            else{
              console.log("negative")
              newArr[i]["price_decrease"] =  Number(e.target.value);
            }
          }
        }
        
      }
      else{
        for (const i in newArr) {
          if (newArr[i]["id"] === row) {
              newArr[i]["interval"] = Number(e.target.value);
          }
        }
      }
      console.log(newArr);
    };
  
    const handleObjective = (e) => {
      setObjective(e.target.value);
      console.log(e.target.value);
    };
    const handleDelete = (row) => {
      console.log(selectedSkus, row)
      toastSuccess("You have successfully deleted");
      setSelectedSkus(selectedSkus.filter((item) => item.id !== row));
      console.log(selectedSkus);
    };
    const handleOptimization = () => {
      // const lastChangedValues = {};
      if (valueBrand == "") {
        toastError("you Must choose brand");
      } else {
        if (objective == "") {
          toastError("you Must choose objective");
  
        } else {
          setLoading(false)
          setFlag(true)
          console.log(selectedSkus);
          console.log(priceIncrease);
          console.log(priceDecrease);
          console.log(intervals);
          bestOptimization(selectedSkus, priceIncrease, priceDecrease, intervals, objective).then((response) => {
            setResultBestOptimization(response.data);
            console.log(response.data);
            setFlag(false);
          });
        }
      }
    };
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setShow(false)
      setOpen(false);
  
      console.log(resultBestOptimization);
    };
  
    return (
      <>
        <ToastContainer />
        <Typography
          sx={{
            color: `${theme.palette.primary.main}`,
            pt: 3,
          
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          Optimization
        </Typography>
  
        <Box
          sx={{
            fontSize: "1.1rem",
            marginBottom: "15px",
            pb: 1,
            color: "#6D6D6D",
          }}
        >
          Turn your data into a competitive advantage by making actionable
          decisions that optimize your business operations.
        </Box>
        <Paper>
          <Grid container spacing={2}>
          <Grid  item xs={6}>
          <Item elevation={4} style={{marginBottom: "20px", marginLeft: "20px"}}>
          <Stack direction="row" justifyContent={"center"}>
            <Box sx={{ m: 1, ml: 1 }}>
              <Typography sx={{fontSize:12, fontWeight: "bold", p: 1, color: "#757575" }}>
                Brand
              </Typography>
              <FormControl sx={{ minWidth: 100, ml: 1 }} size="small">
                <Select
                  sx={{
                    fontSize:12
                  }}
                  value={valueBrand}
                  onChange={handleBrand}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {brand.map((brand, index) => {
                    return (
                      <MenuItem key={index} value={brand}>
                        {brand}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
  
            <Box sx={{ m: 1, ml: 3 }}>
              <Typography sx={{fontSize:12, fontWeight: "bold", p: 1, color: "#757575" }}>
                Objective
              </Typography>
              <FormControl sx={{ minWidth: 100, ml: 1 }} size="small">
                <Select
                  sx={{
                    fontSize:12
                  }}
                  value={objective}
                  onChange={(e) => handleObjective(e)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="Market Share">Market Share</MenuItem>
                  <MenuItem value="value_share">Value Share</MenuItem>
                  <MenuItem value="value_sales">Penetration</MenuItem>
                </Select>

                
              </FormControl>

            </Box>
            </Stack>
            <Stack direction="row" justifyContent={"center"}>
            <Box sx={{ m: 1, ml: 3 }}>
            <Typography sx={{fontSize:12, fontWeight: "bold", p: 1, color: "#757575" }}>
                Price Increase
              </Typography>
            <OutlinedInput
                    id="outlined-basic"
                    size="small"
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    sx={{
                    fontSize:12,
                    width: "80px",
                    ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input ":
                        {
                            height: "0.6rem",
                        },
                    }}
                    // value={row?.interval}
                    onChange={(e) =>
                        setPriceIncrease(e.target.value/100)
                        }
                />
            </Box>
            <Box sx={{ m: 1, ml: 3 }}>
            <Typography sx={{fontSize:12, fontWeight: "bold", p: 1, color: "#757575" }}>
                Price Decrease
              </Typography>
            <OutlinedInput
                    id="outlined-basic"
                    size="small"
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    sx={{
                    fontSize:12,
                    width: "80px",
                    ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input ":
                        {
                            height: "0.6rem",
                        },
                    }}
                    // value={row?.interval}
                    ref={ref}
                    onChange={(e) =>
                        setPriceDecrease(e.target.value/100)
                        }
                />
            </Box>
            <Box sx={{ m: 1, ml: 3 }}>
            <Typography sx={{fontSize:12, fontWeight: "bold", p: 1, color: "#757575" }}>
                Intervals
              </Typography>
            <OutlinedInput
                    id="outlined-basic"
                    size="small"
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    sx={{
                    fontSize:12,
                    width: "80px",
                    ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input ":
                        {
                            height: "0.6rem",
                        },
                    }}
                    // value={row?.interval}
                    ref={ref}
                    onChange={(e) =>
                        setIntervals(e.target.value/100)
                        }
                />
            </Box>
          </Stack>
          <TableContainer sx={{ marginTop: "15px",}}>
            <Table sx={{ minWidth: 450, height: 300}} aria-label="simple table">
              <TableHead>
                <TableRow sx={{fontSize:10}}>
                  <StyledTableCell>No</StyledTableCell>
                  <StyledTableCell>SKU</StyledTableCell>
  
                  <StyledTableCell> ACTIONS </StyledTableCell>
                  <StyledTableCell> Price Increase</StyledTableCell>
  
                  <StyledTableCell>Price Decrease</StyledTableCell>
                  <StyledTableCell>Intervals</StyledTableCell>
                </TableRow>
              </TableHead>
              {skus?.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                      <Box component="img" src={ImgNoData} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody sx={{fontSize:12}}>
                  {selectedSkus?.map((row, index) => (
                    <>
                      
                        <TableRow
                          key={row?.id}
                          sx={{ borderBottom: `1px solid #E4E4E4` }}
                        >
                        
                          <StyledTableCell align="right">
                            {row?.id}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            sx={{ fontWeight: "bold", width:"400px" }}
                          >
                            {row?.sku}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <div>
                              <Box
                                component="img"
                                sx={{cursor:"pointer"}}
                                src={deleteEdit}
                                onClick={() => {
                                  handleDelete(row.id);
                                }}
                              />
                            </div>
                          </StyledTableCell>
                          {index === 0 && (
                            <>
                              <TableCell rowSpan={selectedSkus.length} align="center" sx={{ verticalAlign: "middle" }}>
                              <OutlinedInput
                                    disabled
                                    id="outlined-basic"
                                    size="small"
                                    value={priceIncrease*100}
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    sx={{
                                    fontSize:12,
                                    width: "80px",
                                    ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input ":
                                        {
                                            height: "0.6rem",
                                        },
                                    }}
                                    // value={row?.interval}
                     
                                />
                              </TableCell>
                              <TableCell rowSpan={selectedSkus.length} align="center" sx={{ verticalAlign: "middle" }}>
                              <OutlinedInput
                                    disabled
                                    id="outlined-basic"
                                    size="small"
                                    value={priceDecrease*100}
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    sx={{
                                    fontSize:12,
                                    width: "80px",
                                    ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input ":
                                        {
                                            height: "0.6rem",
                                        },
                                    }}
                                    // value={row?.interval}
                     
                                />
                              </TableCell>
                              <TableCell rowSpan={selectedSkus.length} align="center" sx={{ verticalAlign: "middle" }}>
                              <OutlinedInput
                                    disabled
                                    id="outlined-basic"
                                    size="small"
                                    value={intervals*100}
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    sx={{
                                    fontSize:12,
                                    width: "80px",
                                    ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input ":
                                        {
                                            height: "0.6rem",
                                        },
                                    }}
                                    // value={row?.interval}
                     
                                />
                            </TableCell>
                            </>
                          )}
                          
                        </TableRow>
                        </>
                        ))}
                      </TableBody>     
              )}
            </Table>
          </TableContainer>
          {objective !== "" ? (
              <>
                <Typography
                  variant="outlined"
                  onClick={() => {
                    handleClickOpen();
                    handleOptimization();
                  }}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    
                    },
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ textTransform: "capitalize", fontWeight: "bold", m: 3 , justifyContent: "center"}}
                  >
                    Apply{" "}
                  </Button>
                </Typography>
              </>
            ) : (
              <Button
                variant="contained"
                sx={{ textTransform: "capitalize", fontWeight: "bold", m: 3 }}
                onClick={() => handleOptimization()}
              >
                Apply{" "}
              </Button>
            )}
            </Item>
          </Grid>
          <Grid item xs={6}>
          <Typography
                    sx={{
                      color: `${theme.palette.primary.main}`,
                      fontSize: "20px",
                      fontWeight: 700,
                     
                    }}
                  >
                   Optimum Scenario
                  </Typography>
                  {loading?
                  (<Item elevation={4} style={{marginBottom: "10px", marginRight:"20px", height:"335px", justifyContent:"center"}}>
                      <Typography>
                        Please apply a scenario to show the results
                      </Typography>
                    </Item>):
                    (
                      <Item elevation={4} style={{marginBottom: "20px", marginRight:"20px"}}>
                      {flag ? (
                          <>
                            <Loading2 />
                          </>
                        ) : (
                      <>
                      <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>SKU</TableCell>
                                <TableCell align="center">Optimum Price</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {Object.entries(resultBestOptimization.solution).map(([key, sku]) =>
                                <>
                              <TableRow
                                        key={0}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                        {key}
                                        </TableCell>
                                        <TableCell align="center">{sku.Price.toFixed(2)} SAR</TableCell>
                                    </TableRow>
                              </>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        
                      </>
                      )}
                      </Item>
                    )}
                  
            <Typography
                    sx={{
                      color: `${theme.palette.primary.main}`,
                      fontSize: "20px",
                      fontWeight: 700,
                    }}
                  >
                    Main Competitor
              </Typography>
              {loading ?
                  (<Item elevation={4} style={{marginBottom: "10px", marginRight:"20px", height:"225px", justifyContent:"center"}}>
                      <Typography>
                        Please apply a scenario to show the results
                      </Typography>
                    </Item>):
                    (
            <Item elevation={4} style={{marginBottom: "20px", marginRight:"20px"}}>
            {flag ? (
                          <>
                            <Loading2 />
                          </>
                        ) : (
                          <>
                          <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Brand</TableCell>
                                <TableCell align="center">Sales</TableCell>
                                <TableCell align="center">Market Share</TableCell>
                                <TableCell align="center">Volume Share</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                                {resultBestOptimization.new_stats.top_competitors.map((row) =>
                                <>
                                <TableRow
                                        key={0}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                        {row['Group Brand']}
                                        </TableCell>
                                        <TableCell align="center">{row['value_sales'].toFixed(2)}M SAR</TableCell>
                                        <TableCell align="center">{row['Market Share'].toFixed(2)}</TableCell>
                                        <TableCell align="center">{(row['value_share']*100).toFixed(2)}</TableCell>
                                    </TableRow>
                                    </>
                                )}
                                
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button disabled={flag} onClick={()=>{setShow(true)}}>More Details</Button>
                          </>
                        )}
                  
                    </Item>
                    )}
            
          </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            
  
            <Dialog
              fullScreen={fullScreen}
              open={show}
              maxWidth="xl"
              aria-labelledby="responsive-dialog-title"
            >
              {flag ? (
                <>
                  <Loading />
                </>
              ) : (
                <>
                  <DialogContent>
                    <Stack
                      direction="row"
                      gap={2}
                      sx={{
                        flexWrap: "wrap",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <ChartDountMarketShare
                        marketShare={(
                          resultBestOptimization?.new_stats.volume_share_per
                        )?.toFixed(2)}
                        currentmarketShare={(
                          resultBestOptimization?.base_stats.volume_share_per
                        ).toFixed(2)}
                      />
                      <ChartDountValueShare
                        valueShare={(
                          resultBestOptimization?.new_stats.value_share * 10
                        )?.toFixed(2)}
                        currentvalueShare={(
                          resultBestOptimization?.base_stats.value_share * 10
                        ).toFixed(2)}
                      />
                      <ChartDountRevenue
                        revenue={resultBestOptimization?.new_stats.revenue?.toFixed(2)}
                        currentrevenue={
                          resultBestOptimization?.base_stats.revenue?.toFixed(2)
                        }
                        revenueperc={resultBestOptimization?.new_stats.revenue_per.toFixed(2)}
                        currentrevenueperc={resultBestOptimization?.base_stats.revenue_per.toFixed(2)}
                      />
                    </Stack>
                    <TablePopUp data={resultBestOptimization} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      sx={{
                        border: "1px solid gary",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        mt: 1,
                        mb: 1,
                        color: "gray",
                        border: "1ps solid red",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        m: 1,
                      }}
                      startIcon={<FileDownloadOutlinedIcon />}
                    >
                      Download
                    </Button>
                  </DialogActions>
                </>
              )}
            </Dialog>
          </Box>
        </Paper>
      </>
    );
  };
  
  export default Optimization;
  