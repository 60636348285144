import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  styled,
  tableCellClasses,
  Box,
  useMediaQuery,
} from "@mui/material";
import { getUrl } from "src/Utils/Common";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    padding: "4px", // Further reduce padding
    color: "#4A4A4A",
    fontSize: "10px", // Make text smaller on mobile
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: "#f9f9f9",
    whiteSpace: "nowrap", // Prevents header wrapping issues
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "10px",
    textAlign: "center",
    padding: "3px", // Adjust for mobile
    borderBottom: "none",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  height: 35,
}));

const Badge = styled(Box)(({ theme, bgcolor }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "4px 40px",
  borderRadius: "12px",
  fontSize: "10px", // Smaller font size
  fontWeight: "bold",
  color: "#fff",
  backgroundColor: bgcolor || "#6C63FF",
  minWidth: "28px", // Reduced to prevent breaking layout
  maxWidth: "50px",
  textAlign: "center",
  flexWrap: "wrap",
  wordBreak: "break-word", // Ensures numbers wrap properly
  overflow: "hidden", // Prevents overflow
}));

function MarketComparisonTable() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comparisonType, setComparisonType] = useState("last_month");

  const isMobile = useMediaQuery("(max-width: 600px)"); // Detects mobile screens

  useEffect(() => {
    async function fetchMarketComparison() {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${getUrl()}/market_overview/market_comparison`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              project_id: 1,
              market: "KSA oil base",
              brand: "AFIA",
              comparison_type: comparisonType,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching market comparison data:", error);
        setError("Failed to load data");
      } finally {
        setLoading(false);
      }
    }

    fetchMarketComparison();
  }, [comparisonType]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ padding: "3px", backgroundColor: "#fff" }}>
      <FormControl
        sx={{
          width: "100%", // Makes it fit better on small screens
          maxWidth: "200px", // Prevents excessive width on larger screens
          margin: "auto", // Centers dropdown
          minWidth: isMobile ? 100 : 120, // Adjust dropdown width for mobile
          mb: 2,
          "& .MuiOutlinedInput-root": {
            fontSize: isMobile ? "10px" : "12px", // Smaller font for mobile
            borderRadius: "8px",
            backgroundColor: "#f5f5f5",
            "& fieldset": { borderColor: "#ddd" },
            "&:hover fieldset": { borderColor: "#bbb" },
            "&.Mui-focused fieldset": { borderColor: "#6C63FF" },
          },
          "& .MuiSelect-icon": { color: "#6C63FF" },
        }}
      >
        <Select
          value={comparisonType}
          onChange={(e) => setComparisonType(e.target.value)}
          size="small"
          displayEmpty
        >
          <MenuItem value="last_month">Last Month</MenuItem>
          <MenuItem value="last_3_months">Last 3 Months</MenuItem>
          <MenuItem value="last_year">Last Year (Same Month)</MenuItem>
        </Select>
      </FormControl>

      {/* Make Table Responsive */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "100vw", // Ensures table does not exceed viewport width
          overflowX: "auto", // Enables horizontal scrolling if needed
          display: "block",
          padding: "10px",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "10px",
            width: "100%",
            overflowX: "hidden",
            maxWidth: "100%",
            display: "block",
            whiteSpace: "nowrap",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          <Table stickyHeader aria-label="market comparison table">
            {isMobile ? (
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {isMobile ? "Metric" : "Segments"}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ textAlign: "center", color: "#6C63FF" }}
                  >
                    Market
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ textAlign: "center", color: "#1abc9c" }}
                  >
                    Manufacturer
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow>
                  <StyledTableCell>Segments</StyledTableCell>
                  <StyledTableCell>Value Sales ($)</StyledTableCell>
                  <StyledTableCell>Growth (%)</StyledTableCell>
                  <StyledTableCell>Volume Sales</StyledTableCell>
                  <StyledTableCell>Growth (%)</StyledTableCell>
                  <StyledTableCell>Price Index</StyledTableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isMobile ? (
                // Mobile Layout - Row Headers
                <>
                  {[
                    {
                      label: "Value Sales ($)",
                      key: "Value_Sales",
                      color: "#6C63FF",
                    },
                    {
                      label: "Growth (%)",
                      key: "Value_Sales_Growth",
                      color: "#afa0f8",
                    },
                    {
                      label: "Volume Sales",
                      key: "Volume_Sales",
                      color: "#6C63FF",
                    },
                    {
                      label: "Growth (%)",
                      key: "Volume_Sales_Growth",
                      color: "#afa0f8",
                    },
                    {
                      label: "Price Index",
                      key: "Price_Index",
                      color: "#afa0f8",
                    },
                  ].map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        sx={{
                          fontWeight: "bold",
                          textAlign: "left",
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        {row.label}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Badge bgcolor={row.color}>
                          {data?.Marketplace?.[row.key]?.toFixed(2) || "N/A"}
                        </Badge>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Badge bgcolor="#1abc9c">
                          {data?.Manufacturer?.[row.key]?.toFixed(2) || "N/A"}
                        </Badge>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              ) : (
                // Desktop Layout - Column Headers
                <>
                  <StyledTableRow>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", color: "#6C63FF" }}
                    >
                      Market
                    </StyledTableCell>
                    <StyledTableCell>
                      <Badge bgcolor="#6C63FF">
                        {data?.Marketplace?.Value_Sales?.toFixed(2) || "N/A"}
                      </Badge>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Badge bgcolor="#afa0f8">
                        {data?.Marketplace?.Value_Sales_Growth?.toFixed(2) ||
                          "N/A"}
                      </Badge>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Badge bgcolor="#6C63FF">
                        {data?.Marketplace?.Volume_Sales?.toFixed(2) || "N/A"}
                      </Badge>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Badge bgcolor="#afa0f8">
                        {data?.Marketplace?.Volume_Sales_Growth?.toFixed(2) ||
                          "N/A"}
                      </Badge>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Badge bgcolor="#afa0f8">
                        {data?.Marketplace?.Price_Index?.toFixed(2) || "N/A"}
                      </Badge>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", color: "#1abc9c" }}
                    >
                      Manufacturer
                    </StyledTableCell>
                    <StyledTableCell>
                      <Badge bgcolor="#1abc9c">
                        {data?.Manufacturer?.Value_Sales?.toFixed(2) || "N/A"}
                      </Badge>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Badge bgcolor="#71cfc0">
                        {data?.Manufacturer?.Value_Sales_Growth?.toFixed(2) ||
                          "N/A"}
                      </Badge>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Badge bgcolor="#1abc9c">
                        {data?.Manufacturer?.Volume_Sales?.toFixed(2) || "N/A"}
                      </Badge>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Badge bgcolor="#71cfc0">
                        {data?.Manufacturer?.Volume_Sales_Growth?.toFixed(2) ||
                          "N/A"}
                      </Badge>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Badge bgcolor="#71cfc0">
                        {data?.Manufacturer?.Price_Index?.toFixed(2) || "N/A"}
                      </Badge>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default MarketComparisonTable;
