import ReactApexChart from "react-apexcharts";
import { theme } from "../../../Theme/index";
import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getUrl } from "src/Utils/Common";
import { useMediaQuery } from "@mui/material";

function BarChartWobContribution() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const min = 0;
  const max = 100;
  const currentChartType = useSelector((state) => state.chart.currentChartType);

  // State for data values and categories
  const [dataValues, setDataValues] = useState([]);
  const [categories, setCategories] = useState([]);

  const name = "Contribution to Growth-Decline Y23";

  // Get project_id from Redux state
  const projectId = useSelector((state) => state.userProject.projectId);

  // Fetch data from API when component mounts or projectId changes
  useEffect(() => {
    async function fetchGrowthContribution() {
      if (!projectId) {
        console.warn("Project ID is not set in Redux state.");
        return;
      }
      const [hierarchy, hierarchy_value] = currentChartType?.split(",") || [
        "brand",
        "AFIA",
      ];

      try {
        const response = await fetch(
          `${getUrl()}/market_overview/growth_contribution`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-cors-api-key": "temp_8c8f91f122df0ef9daa49a6701f13f76",
            },
            body: JSON.stringify({
              project_id: projectId,
              // hierarchy: "brand",
              hierarchy: hierarchy,
              period_level: "quarter",
              target: 1,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        // Use API response to update data values and categories
        setCategories(result.brand);
        setDataValues([
          {
            type: "bar",
            name: "Contribution to Growth",
            data: result["Contribution to Growth"],
          },
        ]);

        console.log("API Response Processed:", result);
      } catch (error) {
        console.error("Error fetching growth contribution data:", error);
      }
    }

    fetchGrowthContribution();
  }, [projectId, currentChartType]);

  // // Selector to retrieve Redux state for triggering updates
  // const currentDateComparison = useSelector(
  //   (state) => state.dateCompare.currentDateComparison
  // );

  // // Simulate data updates when `currentDateComparison` changes
  // useEffect(() => {
  //   const updatedData = Array.from(
  //     { length: 7 },
  //     () => (Math.random() * 200 - 100).toFixed(1) // Generate random values between -100 and 100
  //   );

  //   setDataValues([
  //     {
  //       type: "bar",
  //       name: "Contribution to Growth",
  //       data: updatedData,
  //     },
  //   ]);

  //   console.log(`Updated data for currentDateComparison: ${updatedData}`);
  // }, [currentDateComparison]);

  const getYAxisFormatter = (val) => {
    return `${val.toFixed(1)}%`;
  };

  // Chart options
  const [options] = useState({
    chart: {
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      height: "100%", // Ensure it adapts to container height
      parentHeightOffset: 0, // Prevents extra padding that causes scrolling
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "%";
      },

      offsetY: isMobile ? -5 : -10, // Reduce offset on mobile to avoid overlap
      style: {
        fontSize: isMobile ? "8px" : "11px", // Smaller font size for mobile
      },
    },
    xaxis: {
      categories: categories, // Dynamically updated categories
      labels: {
        style: {
          fontSize: isMobile ? "7px" : "9px", // Smaller font for mobile
          fontWeight: 500,
        },
        rotate: isMobile ? -45 : 0, // Rotate labels on mobile to prevent cut-off
        trim: true, // Ensure labels are truncated instead of overflowing
      },
      tooltip: {
        enabled: true,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      seriesName: "bar",
      min: min,
      max: max,
      title: {
        text: "Contribution to Growth",
      },
      labels: {
        formatter: getYAxisFormatter,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        columnWidth: isMobile ? "60%" : "25%", // Wider bars on mobile for better visibility
        dataLabels: {
          position: "top", // Place data labels on top of the bars
        },
        colors: {
          ranges: [
            {
              from: -100,
              to: 0,
              color: "#FF4560", // Red for negative values
            },
            {
              from: 0,
              to: 100,
              color: "#00E396", // Green for positive values
            },
          ],
        },
      },
    },
    fill: {
      opacity: 1, // Ensure bars are fully opaque, no transparency issues
    },
    responsive: [
      {
        breakpoint: 900, // Width in pixels
        options: {
          chart: {},
          legend: {
            position: "top",
          },
        },
      },
      {
        breakpoint: 3900, // Width in pixels
        options: {
          chart: {},
          legend: {
            position: "top",
          },
        },
      },
    ],
  });

  return (
    <>
      <Typography fontWeight={theme.typography.fontWeightBold} px={1}>
        {name}
      </Typography>
      {dataValues.length > 0 ? (
        <ReactApexChart
          options={{ ...options, xaxis: { ...options.xaxis, categories } }}
          series={dataValues}
          // width={500}
          // height={300}
          width={isMobile ? "120%" : 500} // Responsive width
          height={isMobile ? 250 : 300} // Adjust height for mobile
        />
      ) : (
        <Typography>Loading...</Typography>
      )}
    </>
  );
}

export default BarChartWobContribution;
