import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { theme } from "../../../Theme/index";
import { getUrl } from "src/Utils/Common";

function CannibalizationTable() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from the API
  useEffect(() => {
    async function fetchData() {
      const TIMEOUT = 1000000; // Set timeout to 10 seconds (10000ms)

      // Create a promise that rejects after the timeout
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Request timed out")), TIMEOUT)
      );

      try {
        // Use Promise.race to race between the fetch and the timeout
        const response = await Promise.race([
          fetch(`${getUrl()}/sim/strategy_table`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-cors-api-key": "temp_8c8f91f122df0ef9daa49a6701f13f76",
            },
            body: JSON.stringify({ project_name: "newsavola2" }),
          }),
          timeoutPromise,
        ]);

        if (!response.ok) {
          throw new Error(`API error: ${response.statusText}`);
        }

        const data = await response.json();

        // Map the API response to table rows
        const mappedData = data.map((item) => {
          const strategyMapping = {
            "if you increase its price, Keep lower prices as they are":
              "Increase prices of the lower SKUs",
            "Value Driver SKU":
              "Increase shelf price with margin & revenue optimized",
            "Up-laddering":
              "Encourage up-laddering, optimize index between tiers",
            "Not a Volume Driver": "Don't Promote",
          };

          return {
            skuName: item["SKU Name"],
            cannibalization: Math.abs(item.cannibalization * 100).toFixed(2), // Convert to percentage
            topSku: item.top_sku_in_interaction,
            strategy: strategyMapping[item.incr_role] || "", // Assign mapped value or empty string if not found
            volumeDriver: item.volume_driver,
            valueDriver: item.value_driver,
            minPrice: item.with_lower_price ? "-" : "-",
            maxPrice: item.with_higher_price ? "-" : "-",
          };
        });

        setRows(mappedData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  // Helper function to color code cannibalization
  const getColor = (value) => {
    const numericValue = parseFloat(value);
    if (numericValue >= 70) return "#FF6347"; // Red for high cannibalization
    if (numericValue >= 60) return "#7392f8"; // Yellow for medium cannibalization
    return "#90EE90"; // Green for low cannibalization
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  return (
    <Box sx={{ width: "100%", overflowX: "auto", display: "block" }}>
      <Typography variant="h6" component="h2" gutterBottom>
        SKU Cannibalization Table
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          overflowX: "auto", // ✅ Enables horizontal scrolling on small screens
          width: "100%", // ✅ Ensures table fits within its container
          display: "block", // ✅ Prevents layout collapse
          whiteSpace: "nowrap", // ✅ Prevents text wrapping causing overflow
        }}
      >
        <Table sx={{ minWidth: "100%", tableLayout: "fixed" }}>
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffffff",
              zIndex: 10,
              "& th": {
                fontSize: { xs: "10px", sm: "12px" },
                padding: "10px", // Increased padding for better spacing
                minWidth: "120px", // Ensures columns have a minimum width
                // I want to wrap text in cells
                // wordWrap: "break-word",
                textOverflow: "ellipsis", // Cuts off long text with "..." instead of overflowing
                whiteSpace: "nowrap", // Prevents text wrapping in headers
              },
            }}
          >
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "bold",
                  padding: "6px", // ✅ More space for readability
                  fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                  wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                  textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  overflow: "hidden",
                }}
              >
                SKU Name
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  // padding: "6px", // ✅ More space for readability
                  fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                  wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                  textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  overflow: "hidden",
                }}
              >
                Cannibalization (%)
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  padding: "6px", // ✅ More space for readability
                  fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                  wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                  textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  overflow: "hidden",
                }}
              >
                Top SKU in Interaction
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  padding: "6px", // ✅ More space for readability
                  fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                  wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                  textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  overflow: "hidden",
                }}
              >
                Strategy
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  padding: "6px", // ✅ More space for readability
                  fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                  wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                  textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  overflow: "hidden",
                }}
                align="center"
              >
                Volume Driver
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  padding: "6px", // ✅ More space for readability
                  fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                  wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                  textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  overflow: "hidden",
                }}
                align="center"
              >
                Value Driver
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  padding: "6px", // ✅ More space for readability
                  fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                  wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                  textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  overflow: "hidden",
                }}
                align="center"
              >
                Min Price
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  padding: "6px", // ✅ More space for readability
                  fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                  wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                  textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  overflow: "hidden",
                }}
                align="center"
              >
                Max Price
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} sx={{ height: "24px" }}>
                <TableCell
                  sx={{
                    padding: "8px",
                    fontSize: { xs: "9px", sm: "12px" },
                    wordWrap: "break-word", // Allows text to wrap inside the cell
                    textOverflow: "ellipsis", // Shows "..." if text is too long
                    overflow: "hidden",
                  }}
                >
                  {row.skuName}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "6px", // ✅ More space for readability
                    fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                    wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                    textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                    overflow: "hidden",
                  }}
                  style={{
                    backgroundColor: getColor(row.cannibalization),
                    color: "#000",
                  }}
                >
                  {row.cannibalization}%
                </TableCell>
                <TableCell
                  sx={{
                    padding: "6px", // ✅ More space for readability
                    fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                    wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                    textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                    overflow: "hidden",
                  }}
                >
                  {row.topSku}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "6px", // ✅ More space for readability
                    fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                    wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                    overflow: "hidden",

                    textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  }}
                >
                  {row.strategy}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "6px", // ✅ More space for readability
                    fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile

                    overflow: "hidden",
                    wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                    textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  }}
                >
                  {row.volumeDriver && <CheckIcon color="success" />}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "6px", // ✅ More space for readability
                    overflow: "hidden",

                    fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                    wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                    textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  }}
                >
                  {row.valueDriver && <CheckIcon color="success" />}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "6px", // ✅ More space for readability
                    overflow: "hidden",
                    fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                    wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                    textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  }}
                >
                  {row.minPrice}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    overflow: "hidden",
                    padding: "6px", // ✅ More space for readability
                    fontSize: { xs: "9px", sm: "12px" }, // ✅ Adjust font size for mobile
                    wordWrap: "break-word", // ✅ Ensures text breaks instead of causing overflow
                    textOverflow: "ellipsis", // ✅ Cuts off long text with "..." instead of overflowing
                  }}
                >
                  {row.maxPrice}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CannibalizationTable;
